import React, { Component, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import { checkIfIsAdmin, isAuthenticated } from './core/services/authService'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Protected = ({ children }) => {
  const isAdmin = checkIfIsAdmin()
  let loginUrl = isAdmin ? 'admin/login' : 'login'
  if (!isAuthenticated()) {
    return <Navigate to={loginUrl}></Navigate>
  }
  return children
}

const UnProtected = ({ children }) => {
  if (isAuthenticated()) {
    if (localStorage.getItem('isAdmin')) {
      return <Navigate to="/listorder"></Navigate>
    } else {
      return <Navigate to="/createad"></Navigate>
    }
  }
  return children
}

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const AdminLogin = React.lazy(() => import('./views/pages/login/AdminLogin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            {!checkIfIsAdmin() && (
              <>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  element={
                    <UnProtected>
                      <Login />
                    </UnProtected>
                  }
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  element={
                    <UnProtected>
                      <Register />
                    </UnProtected>
                  }
                />
              </>
            )}
            {checkIfIsAdmin() && (
              <Route
                exact
                path="/admin/login"
                name="Admin Login Page"
                element={
                  <UnProtected>
                    <AdminLogin />
                  </UnProtected>
                }
              />
            )}
            <Route
              exact
              path="/404"
              name="Page 404"
              element={
                <UnProtected>
                  <Page404 />
                </UnProtected>
              }
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              element={
                <UnProtected>
                  <Page500 />
                </UnProtected>
              }
            />
            <Route
              path="*"
              name="Home"
              element={
                <Protected>
                  <DefaultLayout />
                </Protected>
              }
            />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
