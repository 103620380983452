let isLoggedIn = false
export let userDetails = null
export const setUserDetails = (details) => {
  userDetails = details
}

export const getUserDetails = () => {
  return userDetails
}

export const isAuthenticated = () => {
  // Implement your authentication logic here (e.g., check for a valid token).
  // Return true if authenticated, false otherwise.
  const userId = localStorage.getItem('userId')
  const token = localStorage.getItem('token')
  if (userId && token) {
    isLoggedIn = true
  } else {
    isLoggedIn = false
  }
  return isLoggedIn /* check authentication here */
}

export const checkIfIsAdmin = () => {
  if (localStorage.getItem('isAdmin')) {
    return true
  } else if (window.location.hostname.includes('admin') || window.location.port === '3002') {
    return true
  }
  return false
}
